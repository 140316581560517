export default () => {
  //? ローディングアニメーション中のスクロール不可
  (function() {
    document.querySelector('body').style.overflow = 'hidden';
    function noScroll(event) {
      event.preventDefault();
    }
    document.addEventListener('touchmove', noScroll, { passive: false });
    document.addEventListener('mousewheel', noScroll, { passive: false });

    document.addEventListener("keydown", handleKeydown, { passive: false });
    function handleKeydown(event){
      let keyCode = event.keyCode;
      if (keyCode === 9 || keyCode === 32 || keyCode === 33 || keyCode === 34 || keyCode === 38 || keyCode === 40) {
        event.preventDefault();
      }
    }

    setTimeout(() => {
      document.removeEventListener('touchmove', noScroll, { passive: false });
      document.removeEventListener('mousewheel', noScroll, { passive: false });
      document.removeEventListener("keydown", handleKeydown, { passive: false });
      document.querySelector('body').style.overflow = 'initial';
    }, 5500);

    if (navigator.userAgent.match(/(!iPhone|!iPad|!iPod|!Android)/i)) {
      if (window.matchMedia('(min-width: 1025px)').matches) {
        document.querySelector('body').style.overflow = 'hidden';
      }
    }
    if(location.href === 'https://robbiedesignand.web'){
      window.addEventListener('DOMContentLoaded', () => {
        window.scrollTo(0,0)
      })
    }
  })();
  //? / ローディングアニメーション中のスクロール不可

  //? .header__navi-buttonの生成と削除
  const header = document.querySelector('.header');
  const naviButton = document.createElement('div');
  naviButton.classList.add('header__navi-button');
  const bars = [
    document.createElement('span'),
    document.createElement('span'),
    document.createElement('span')
  ];
  const navi = document.querySelector('.header__navi');
  const naviItems = document.querySelectorAll('.header__navi__item');
  const headerLogoLink = document.querySelector('.header__logo__link');

  function appendNaviButton() {
    if (naviButton) {
      for (let i = 0; i < bars.length; i++) {
        bars[i].classList.add('header__navi-button__bar');
        naviButton.appendChild(bars[i]);
      }
      header.appendChild(naviButton);
    }
  }

  function removeNaviButton() {
    const naviButton = document.querySelector('.header__navi-button');
    if (naviButton) {
      naviButton.remove();
    }
  }

  appendNaviButton();

  if (window.matchMedia('(min-width: 768px)').matches) {
    removeNaviButton();
  }

  window.addEventListener('resize', () => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      appendNaviButton();
      navi.style.transition = '0s';
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      removeNaviButton();
      navi.style.transition = '0s';
    }
  }, true);
  //? / .header__navi-buttonの生成と削除

  //? .header__navi-buttonのアニメーション
  naviButton.addEventListener('click', e => {
    e.preventDefault();
    naviButton.classList.toggle('header__navi-button--active');
    navi.classList.toggle('header__navi--active');
    navi.style.transition = '.4s';
  });
  for(let i in naviItems) {
    if (naviItems.hasOwnProperty(i)) {
      naviItems[i].addEventListener('click', e => {
        e.preventDefault();
        if (window.matchMedia('(max-width: 767px)').matches) {
          naviButton.classList.toggle('header__navi-button--active');
          navi.classList.toggle('header__navi--active');
        }
      });
    }
  }
  headerLogoLink.addEventListener('click', e => {
    e.preventDefault();
    if (naviButton.classList.contains('header__navi-button--active')) {
      naviButton.classList.remove('header__navi-button--active');
    }
    if (navi.classList.contains('header__navi--active')) {
      navi.classList.remove('header__navi--active');
    }
  });
  //? / .header__navi-buttonのアニメーション

  //? SP時のviewport height 100%
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  window.addEventListener('resize', setFillHeight);

  window.addEventListener('DOMContentLoaded', () => {
    setFillHeight();
  });
  //? / SP時のviewport height 100%

  //? モーダル
  function openModal() {
    let modal = document.getElementById('modal');
    let modalContent = document.querySelector('.modal__content')
    modal.classList.add('modal-is-shown');
    modalContent.classList.add('modal-content-is-shown');
  }

  document.querySelector('.open-modal').onclick = () => {
    openModal();
    document.querySelector('body').style.overflow = 'hidden';
  }

  function closeModal() {
    let modal = document.getElementById('modal');
    let modalContent = document.querySelector('.modal__content')
    modal.classList.remove('modal-is-shown');
    modalContent.classList.remove('modal-content-is-shown');
  }

  document.getElementById('modal').onclick = () => {
    closeModal();
    document.querySelector('body').style.overflow = 'initial';
  }

  document.addEventListener('keydown', handleKeydown, { passive: false });
  function handleKeydown(event){
    const modal = document.getElementById('modal');
    let keyCode = event.keyCode;
    if (modal.classList.contains('is-shown') && keyCode === 27) {
      closeModal();
    }
  }
  //? / モーダル
}
