import LocomotiveScroll from 'locomotive-scroll';
import Functions from './functions.js';
import '../scss/reset.scss';
import '../scss/loading-block.scss';
import '../../node_modules/locomotive-scroll/src/locomotive-scroll.scss';
import '../scss/top-locomotive-scroll.scss';
import '../scss/elements-floating.scss';
import '../scss/common.scss';
import '../scss/main.scss';
import '../scss/modal.scss';
import ogp from '../images/ogp.jpg';

setTimeout(() => {
  const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
  });
}, 5500);

Functions();

img.src = ogp;
